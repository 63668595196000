.menus-list{
    list-style: none;
    padding: 0px;
}

.restaurant-title{
    text-align: center;
    font-size: 45px;
    color: var(--color-primary-600);
    font-weight: 50px;
}

@media only screen and (max-width: 500px) {
    .restaurant-title {
      font-size: 25px;
    }
  }

