.nav-links {
    list-style: none;
    margin-top: 20px;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    text-decoration: none;
    padding: 0.5rem;
    color: black;
    font-size: 20px;
    color: white;
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    background: var(--color-primary-600);
    border-radius: 5px;
    color: black;
  }
  
  .nav-links button {
    cursor: pointer;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      text-decoration: none;
    }
  
    .nav-links button {
      background: transparent;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: var(--color-primary-600);
    }
  }
  