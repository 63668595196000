.form-control {
    margin: 1rem 0;
  }
  
  .form-control input{
    -webkit-apperance: none;
  }
  
  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-control input,
  .form-control textarea {
    width: 99%;
    font: inherit;
    border: 1px solid var(--color-surface-mixed-600);
    background: var(--almost-white);
  }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #510077;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }

label{
  color: white;
}