.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 2px solid #ad0202;
    border-radius: 4px;
    background: #ad0202;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button--inverse {
    background: transparent;
    color: white;
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: white;
    background: #f34343;
  }
  
  .button--danger {
    background: #ad0202;
    border-color: #ad0202;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--small {
    font-size: 0.8rem;
  }
  
  .button--big {
    font-size: 1.5rem;
  }

  .random--restaurant{
    background-color: var(--color-primary-100);
    border-color: var(--color-primary-100);
  }

  .random--restaurant:hover,
  .random-restaurant:active{
    background-color: var(--color-primary-300);
    border-color: var(--color-primary-300);
  }

  @media only screen and (max-width: 500px){
    .random--restaurant{
      font-size: 13px;
    }
  }