.authentication__header {
    color: white;
    text-align: center;
  }
    
  .authentication {
    width: 90%;
    max-width: 25rem;
    margin: 7rem auto;
    text-align: center;
  }
    
  .authentication form {
    margin-bottom: 1rem;
  }
  
  .login-title{
    font-size: 45px;
    text-align: center;
  }
  
  .signup-form {
    list-style: none;
    margin: 0 auto;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    background: var(--almost-white);
  }
  