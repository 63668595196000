.modal {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 10%;
    width: 80%;
    background: var(--color-surface-mixed-300);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
  }
  
  .modal__header {
    width: 100%;
    padding: 1rem 0.5rem;
    background: var(--color-primary-600);
    padding-right: 0px;
    padding-left: 0px;
    border-radius: 8px;
    border-bottom: initial;
  }
  
  .modal__header h2 {
    margin: 0.5rem;
    color: var(--color-surface-mixed-100);
    margin-left: 20px;
  }
  
  .modal__content {
    padding: 1rem 0.5rem;
    color: white;
    font-size: 20px;
    margin-left: 10px;
  }
  
  .modal__footer {
    padding: 1rem 0.5rem;
    margin-left: 10px;
  }
  
  @media (min-width: 768px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
  
  .modal-enter {
    transform: translateY(-10rem);
    opacity: 0;
  }
  
  .modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .modal-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .modal-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
  }
  