h2, h3{
    color: var(--almost-white);
}

.lasagne-alarm-card{
    background-color: #fff6af;
    border: 5px solid #ffeb3b;
}

.lasagne-alarm-name, .lasagne-alarm-price{
    color: black;
}