.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border: 5px solid var(--color-surface-mixed-300);
  padding: 1rem;
  overflow: hidden;
  background: var(--color-surface-mixed-500);
  width: 60%;
  height: 70%;
  margin-top: 15px; 
}

.user-item__content {
  padding: 0;
}

@media only screen and (max-width: 1700px) {
  .card {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .card {
    font-size: 10px;
    width: 70%;
  }
}
