a {
    text-decoration: underline;
    font-size: 25px;
}

h1, a, h2{
    color: white;
}

.buttons{
    margin-top: 20px;
}