@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@200&family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,400;8..144,800&family=Roboto+Mono:wght@300;400;600;700&family=Rubik&display=swap');

:root{
  --color-surface-mixed-100: #1c1820;
  --color-surface-mixed-200: #312d35;
  --color-surface-mixed-300: #47444b;
  --color-surface-mixed-400: #5f5b62;
  --color-surface-mixed-500: #77757a;
  --color-surface-mixed-600: #918f93;
  
  --color-primary-100: #714bb3;
  --color-primary-200: #825ebc;
  --color-primary-300: #9271c4;
  --color-primary-400: #a284cd;
  --color-primary-500: #b298d5;
  --color-primary-600: #c1acde;
  --red: #e3231c;
  --almost-white: #E6E6E6; 
}

body {
  margin: 0;
  font-family: 'Martian Mono', monospace;
  font-family: 'Roboto Flex', sans-serif;
  font-family: 'Roboto Mono', monospace;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-surface-mixed-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-content{
  text-align: center;
}

.title {
  color: var(--color-primary-600);
  font-size: 80px;
}

@media only screen and (max-width: 1900px){
  .title {
    font-size: 50px;
  }
}