.update-restaurant-form {
    list-style: none;
    margin: 0 auto;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    background: var(--color-surface-mixed-400);
  }
  
  .update-restaurant-title{
    text-align: center;
    font-size: 45px;
    color: var(--color-primary-600);
  }